/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


            var isMobile = {
              Android: function() {
                return navigator.userAgent.match(/Android/i);
              },
              BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
              },
              iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
              },
              Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
              },
              Windows: function() {
                return navigator.userAgent.match(/IEMobile/i);
              },
              any: function() {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
              }
          };

            // Animate content on scroll
            var contentWayPoint = function() {
              var i = 0;
              $('.animate-box').waypoint( function( direction ) {

                  if( direction === 'down' && !$(this.element).hasClass('animated') ) {

                      i++;

                      $(this.element).addClass('item-animate');
                      setTimeout(function(){

                          $('body .animate-box.item-animate').each(function(k){
                              var el = $(this);
                              setTimeout( function () {
                                  el.addClass('fadeInUp animated');
                                  el.removeClass('item-animate');
                              },  k * 200, 'easeInOutExpo' );
                          });

                      }, 100);

                  }

              } , { offset: '85%' } );
          };


          // Loading page
          var loaderPage = function() {
              $(".loader").fadeOut("slow");
          };


          var openPopupLink = function() {

              $('.popup-youtube').magnificPopup({
                  disableOn: 700,
                  type: 'iframe',
                  mainClass: 'mfp-fade',
                  removalDelay: 160,
                  preloader: false,
                  fixedContentPos: false
              });

              $('.open-popup-link').magnificPopup({
                  type: 'inline',
                  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
              });

              var player = null;
              $('.open-popup-video-link').magnificPopup({
                  type: 'inline',
                  midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
                  callbacks: {
                      open: function () {
                          var $video = $(this.content).children('video');

                          $video.mediaelementplayer({
                              // Do not forget to put a final slash (/)
                              pluginPath: 'https://cdnjs.com/libraries/mediaelement/',
                              // this will allow the CDN to use Flash without restrictions
                              // (by default, this is set as `sameDomain`)
                              shimScriptAccess: 'always',
                              success: function(mediaElement, domObject, instance) {
                                  var videoElem = $video.get(0);
                                  player = instance;

                                  if(videoElem.requestFullscreen) {
                                      videoElem.requestFullscreen();
                                  } else if(videoElem.mozRequestFullScreen) {
                                      videoElem.mozRequestFullScreen();
                                  } else if(videoElem.webkitRequestFullscreen) {
                                      videoElem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                                      if(!document.webkitCurrentFullScreenElement) {
                                          videoElem.webkitRequestFullscreen();
                                      }
                                  } else if (videoElem.msRequestFullscreen) {
                                      videoElem.msRequestFullscreen();
                                  }

                                  player.play();
                              }
                          });
                      },
                      close: function () {
                          player.pause();
                          player.remove();
                          player = null;
                      },
                  },
              });
          };



              var owlCarouselInit = function () {


                  var $owl = $('.silhouette-carousel');
                  $owl.owlCarousel({
                      items: 1,
                      center: true,
                      loop: true,
                      nav: true,
                      navText: ['<i class="ion-ios-arrow-thin-left"></i>', '<i class="ion-ios-arrow-thin-right"></i>'],
                      dots: false,
                      autoplay: false,
                      mouseDrag: true,
                      smartSpeed: 500,
                      margin: 10

                  });
              };

              var initMenu = function () {

                  var container = $('#fullpage'),
                      triggerBttn = $('#nav-icon'),
                      overlay = $('.overlay'),
                      transEndEventNames = {
                          'WebkitTransition': 'webkitTransitionEnd',
                          'MozTransition': 'transitionend',
                          'OTransition': 'oTransitionEnd',
                          'msTransition': 'MSTransitionEnd',
                          'transition': 'transitionend'
                      },
                      transEndEventName = transEndEventNames[Modernizr.prefixed('transition')],
                      support = {transitions: Modernizr.csstransitions};


                  function toggleOverlay() {
                      if (overlay.hasClass('open')) {
                          overlay.removeClass('open');
                          triggerBttn.toggleClass('open');
                          container.removeClass('overlay-open');
                          overlay.addClass('close');
                          var onEndTransitionFn = function (ev) {
                              if (support.transitions) {
                                  if (ev.propertyName !== 'visibility') {
                                      return;
                                  }
                                  this.removeEventListener(transEndEventName, onEndTransitionFn);
                              }
                              overlay.removeClass('close');
                          };
                          if (support.transitions) {
                              overlay.on(transEndEventName, onEndTransitionFn);
                          }
                          else {
                              onEndTransitionFn();
                          }
                      }
                      else if (!overlay.hasClass('open')) {
                          overlay.removeClass('close');
                          overlay.addClass('open');
                          triggerBttn.toggleClass('open');
                          container.addClass('overlay-open');
                      }
                  }

                  triggerBttn.on('click', toggleOverlay);
              };

              var sameHeightCol = function () {
                  $('.intro-col').matchHeight();
              };

              var fullPageInit = function () {
                  $('#fullpage').fullpage({

                      menu: '#menu',
                      css3: true,
                      scrollingSpeed: 700,
                      verticalCentered: true,
                      parallax: true,
                      parallaxKey: '9034FA71-0058499E-AF0D59B6-7BDF5195',
                      parallaxOptions: {type: 'reveal', percentage: 62, property: 'translate'},

                  });


              };

              // Document on load.
              $(function () {
                  openPopupLink();
                  sameHeightCol();
                  owlCarouselInit();
                  contentWayPoint();
                  fullPageInit();
                  initMenu();
                  loaderPage();

              });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
